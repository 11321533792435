import { Link } from "gatsby";
import React from "react";

const Checkbox = ({ label, isSelected, onCheckboxChange }) => (
  <div className="form-check">
    <label>
      <input
        type="checkbox"
        name={label}
        checked={isSelected}
        onChange={onCheckboxChange}
        className="form-check-input"
      />
      * Ich akzeptiere
      <Link to="/agb"> die Allgemeinen Geschäftsbedingungen</Link> und{" "}
      <Link to="/impressum">die Datenschutzvereinbarung </Link>
      von PunschTaxi.
    </label>
  </div>
);

export default Checkbox;
