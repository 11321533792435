import { Field, Form, Formik } from "formik";
import { Link, navigate } from "gatsby";
import React, {
  Fragment,
  useContext,
  useEffect,
  useState,
  useRef,
} from "react";
import { date, object, string } from "yup";
import {
  intervalToDuration,
  isDate,
  parse,
  setHours,
  setMinutes,
} from "date-fns";

import Cart from "../components/Cart";
import { CartContext } from "../cartContext";
import Checkbox from "../components/Checkbox";
import { FormContext } from "../formContext";

function Checkout01() {
  const checkboxRef = useRef();

  const { cart, countCart, hasDayPlus, hasOnlyGutschein } =
    useContext(CartContext);
  let initialValues = {
    fullname: "",
    email: "",
    phone: "",
    deliverydate: "",
    deliverytime: "",
    deliverytimeEnd: "",
    stiege: "",
    tur: "",
    stock: "",
    cart: "",
    formattedCart: "",
    paymentID: "",
    distance: 0,
    single: 0,
    double: 0,
    address: "",
    lat: 0,
    lng: 0,
    codepromo: "",
    firma: false,
  };
  const [agbread, setagbread] = useState(false);
  const { checkout, saveForm } = useContext(FormContext);
  const [sForm, setsForm] = useState(initialValues);
  const phoneRegExp =
    /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
  function parseDateString(value, originalValue) {
    const parsedDate = isDate(originalValue)
      ? originalValue
      : parse(originalValue, "yyyy-MM-dd", new Date());
    return parsedDate;
  }
  function parseTimeString(value) {
    if (value === undefined) return 0;
    const parsedTime = value.split(":", 2);
    const _pt = Number(parsedTime[0]);
    return _pt;
  }
  function parseTimeMinuteString(value) {
    if (value === undefined) return 0;
    const parsedTime = value.split(":", 2);
    const _pt = Number(parsedTime[1]);
    return _pt;
  }
  const thisday = new Date();
  const day = thisday.getDate();
  const month = thisday.getMonth();
  const year = thisday.getFullYear();

  const today = new Date(year, month, day, 0, 0, 0);
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 2);
  const reopening = new Date(2022, 10, 1, 0, 0, 0);
  const [hasdayplus, sethasdayplus] = useState(false);
  // est-ce qu'il faut un jour de preparation ?

  useEffect(() => {
    sethasdayplus(hasDayPlus());
    return () => {};
  }, [cart]);
  const subscriptionSchema = object().shape({
    fullname: string()
      .required("Bitte Namen ausfüllen")
      .min(2, "Bitte Namen ausfüllen"),

    email: string()
      .required("Angabe Email wird benötigt")
      .email("Bitte gültige Email eingeben")
      .min(6, "Email muß aus mind. 6 Zeichen bestehen")
      .max(100, "Email darf aus max. 100 Zeichen bestehen"),
    phone: string()
      .required("Angabe Telefon-Nr. wird benötigt")
      .min(8, "Telefon-Nr. Muß aus mind. 8 Zahlen bestehen")
      .matches(phoneRegExp, "Telefon-Nr. Ist nicht gültig"),
    deliverydate: date()
      .transform(parseDateString)
      .required("Bitte Zustelldatum auswählen")
      .min(
        // hasdayplus ? tomorrow : today,
        // hasdayplus ? "Manche Equipment-Produkte musst Du 48 Std im voraus bestellen" : "Zustelldatum zu früh",
        reopening,
        "Wieder-Eröffnung am 06.11.2022"
      ),
    deliverytime: string()
      .test(
        "is-tooearly",
        "Lieferung zwischen 11 und 23",
        (value) => parseTimeString(value) >= 11 && parseTimeString(value) <= 23
      )
      .required("Bitte Zeitraum für Zustellung auswählen")
      .min(5, "ungültige Uhrzeit"),
    deliverytimeEnd: string()
      .test(
        "is-tooearly",
        "Lieferung zwischen 11 und 23",
        (value) => parseTimeString(value) >= 11 && parseTimeString(value) <= 23
      )
      //  .test("is-notlong", "Give us 2 hours range", (value) => {
      //      if (sForm["deliverytime"] === undefined || sForm["deliverytime"] === "" || value === undefined) {
      //       console.log("cocoti");
      //       return false;
      //     } else {
      //       let _start = new Date();
      //       _start = setHours(_start, parseTimeString(sForm["deliverytime"]));
      //       _start = setMinutes(_start, parseTimeMinuteString(sForm["deliverytime"]));
      //       let _end = new Date();
      //       _end = setHours(_end, parseTimeString(value));
      //       _end = setMinutes(_end, parseTimeMinuteString(value));
      //       const interval = intervalToDuration({ start: _start, end: _end });
      //       if (interval.hours >= 2) {
      //         return true;
      //       } else {
      //         return false;
      //       }
      //     }
      //   })
      .required("Bitte Zeitraum für Zustellung auswählen")
      .min(5, "ungültige Uhrzeit"),
  });
  const totalCart = countCart().totalCart;

  useEffect(() => {
    if (checkout.length > 0) {
      setsForm(checkout[0]);
    }
    return () => {};
  }, [checkout]);

  return (
    <Fragment>
      <Formik
        initialValues={sForm}
        enableReinitialize
        mapPropsToErrors
        validationSchema={subscriptionSchema}
        onSubmit={(values) => {
          let newObject = JSON.parse(JSON.stringify(sForm));
          newObject["fullname"] = values.fullname;
          newObject["email"] = values.email;
          newObject["phone"] = values.phone;
          newObject["deliverydate"] = values.deliverydate;
          newObject["deliverytime"] = values.deliverytime;
          newObject["deliverytimeEnd"] = values.deliverytimeEnd;
          newObject["firma"] = values.firma;
          saveForm(newObject);
          navigate("/Checkout03");
        }}
      >
        {(formik, isSubmitting) => {
          return (
            <Form
              name="Checkout02"
              className="needs-validation"
              netlify="true"
              netlify-honeypot="bot-field"
            >
              <div className="page-title-overlap bg-accent pt-2">
                <div className="container d-lg-flex justify-content-between py-4 py-lg-3">
                  <div className="pl-lg-2 order-lg-2 mb-0 mb-lg-0 pt-lg-2 breadcrumb breadcrumb-light flex-lg-nowrap justify-content-center">
                    <p className="text-white">
                      <Link to="/Checkout01" alt="checkout">
                        CHECKOUT{" "}
                      </Link>
                      {">"} KUNDENNINFO
                    </p>
                  </div>
                </div>
              </div>
              <section className="container p-0 p-sm-4">
                <div className="row no-gutters py-md-1 py-lg-4 px-2 px-sm-2 px-md-3 px-lg-4">
                  <div className="col-xl-6 mb-3 bg-light box-shadow-lg rounded-lg overflow-hidden px-3 py-3 p-sm-3">
                    <h5 className="mx-2 mt-3 border-bottom">Kundenninfo</h5>
                    <div
                      className={[
                        "container-row pt-3 my-2 mx-2 mb-4 navbar-tool",
                        formik.errors.fullname ? "" : null,
                      ]}
                      id="clientForm"
                    >
                      <div className="row d-flex align-items-center">
                        <div className="col-12 form-group  align-middle frmctrl">
                          <Field
                            type="checkbox"
                            name="firma"
                            className="mr-2"
                          />
                          <label
                            className={
                              formik.values.firma ? "text-accent" : undefined
                            }
                          >
                            {`${
                              formik.values.firma
                                ? "Ist das eine Firmen-Bestellung ?"
                                : "Ist das eine Firmen-Bestellung ?"
                            }`}
                          </label>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 form-group ">
                          <label className="hidden-lbl" htmlFor="fullname">
                            Name
                          </label>

                          <Field
                            name="fullname"
                            className={[
                              formik.errors.fullname
                                ? "form-control frmctrl is-invalid"
                                : formik.touched.fullname
                                ? "form-control frmctrl is-valid"
                                : "form-control frmctrl",
                            ]}
                            //onBlur={handleBlur}
                            placeholder={
                              formik.values.firma ? "Firma Name" : "Name"
                            }
                            type="text"
                          />

                          {formik.errors.fullname ? (
                            <div className="invalid-feedback">
                              {formik.errors.fullname}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      {/* Email, phone number */}
                      <div className="row">
                        <div className="col-sm-6 form-group ">
                          <label className="hidden-lbl" htmlFor="email">
                            Email
                          </label>
                          <Field
                            name="email"
                            className={[
                              formik.errors.email
                                ? "form-control frmctrl is-invalid"
                                : formik.touched.email
                                ? "form-control frmctrl is-valid"
                                : "form-control frmctrl",
                            ]}
                            //onBlur={handleBlur}
                            placeholder="email address"
                            type="text"
                          />
                          {formik.errors.email ? (
                            <div className="invalid-feedback">
                              {formik.errors.email}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-sm-6 form-group ">
                          <label className="hidden-lbl" htmlFor="phone">
                            Phone number
                          </label>
                          <Field
                            name="phone"
                            className={[
                              formik.errors.phone
                                ? "form-control frmctrl is-invalid"
                                : formik.touched.phone
                                ? "form-control frmctrl is-valid"
                                : "form-control frmctrl",
                            ]}
                            //onBlur={handleBlur}
                            placeholder="Phone Number"
                            type="text"
                          />
                          {formik.errors.phone ? (
                            <div className="invalid-feedback">
                              {formik.errors.phone}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>

                    {/* deliverydate, deliverytime */}
                    <h5 className="mx-2 mt-3 border-bottom">Leistungsdatum</h5>
                    {hasdayplus && (
                      <div className="container-row pt-3 my-2 mx-2">
                        <div className="row">
                          <div className="col-12 form-group ">
                            Manche Equipment-Produkte musst Du 48 Std im voraus
                            bestellen
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="container-row pt-3 my-2 mx-2">
                      <div className="row">
                        <div className="col-12 form-group ">
                          <label className="lbl" htmlFor="deliverydate">
                            Delivery date
                          </label>

                          <Field
                            name="deliverydate"
                            className={[
                              formik.errors.deliverydate
                                ? "form-control is-invalid "
                                : formik.touched.deliverydate
                                ? "form-control frmctrl is-valid"
                                : "form-control frmctrl",
                            ]}
                            // placeholder="DD/MM/YYYY"
                            placeholder="yyyy-mm-dd"
                            type="date"
                            mindate={new Date()}
                          />
                          {formik.touched.deliverydate &&
                          formik.errors.deliverydate ? (
                            <div className="invalid-feedback">
                              {formik.errors.deliverydate}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-sm-6  form-group  ml-1 ml-sm-0">
                          <label className="lbl" htmlFor="deliverytime">
                            Between
                          </label>
                          <Field
                            name="deliverytime"
                            className={[
                              formik.errors.deliverytime
                                ? "form-control  is-invalid"
                                : formik.touched.deliverytime
                                ? "form-control frmctrl is-valid"
                                : "form-control frmctrl",
                            ]}
                            placeholder="Time"
                            type="time"
                          />
                          {formik.errors.deliverytime ? (
                            <div className="invalid-feedback">
                              {formik.errors.deliverytime}
                            </div>
                          ) : null}
                        </div>
                        <div className="col-12 col-sm-6 form-group  ml-1 ml-sm-0">
                          <label className="lbl" htmlFor="deliverytimeEnd">
                            And
                          </label>
                          <Field
                            name="deliverytimeEnd"
                            className={[
                              formik.errors.deliverytimeEnd
                                ? "form-control  is-invalid"
                                : formik.touched.deliverytimeEnd
                                ? "form-control frmctrl is-valid"
                                : "form-control frmctrl",
                            ]}
                            placeholder="Time"
                            type="time"
                          />
                          {formik.errors.deliverytimeEnd ? (
                            <div className="invalid-feedback">
                              {formik.errors.deliverytimeEnd}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xl-5 px-sm-2 offset-xl-1 ">
                    <Cart
                      single={checkout["single"]}
                      double={checkout["double"]}
                    />
                    <div className="d-flex flex-column justify-content-between m-4">
                      <Checkbox
                        label={"*"}
                        isSelected={agbread}
                        onCheckboxChange={() => setagbread(!agbread)}
                        key={"agb"}
                      />
                    </div>
                    <div className="d-flex flex-column justify-content-between mb-4">
                      <button
                        disabled={totalCart <= 0 || !agbread}
                        type="submit"
                        className="btn btn-primary btn-shadow btn-block mx-auto"
                        aria-label="Checkout"
                      >
                        <span className="czi-cart mr-2" aria-hidden="false" />
                        Zahlung
                      </button>
                    </div>
                  </div>
                </div>
              </section>
            </Form>
          );
        }}
      </Formik>
    </Fragment>
  );
}

export default Checkout01;
